"use client";
import * as React from "react";
import { useContext } from "react";
import SignIn from "./SignIn";
import UserContext from "@/context/userContext";
import SurveyForm from "./SurveyForm";
import SurveyDataContext from "@/context/surveyDataContext";
import Paper from "@mui/material/Paper";
import { RecCompForm } from "./RecCompForm";
import SelectedExperContext from "@/context/selectedExperContext";
import CustomExperContext from "@/context/customExperContext";
import { Typography } from "@mui/material";
import SelectedExperPanel from "./SelectedExperPanel";
import UserSelectExperPanel from "./UserSelectExperPanel";
import { UserSelectForm } from "./UserSelectForm";

/**
 * A React component that renders the main page of the YEX application.
 *
 * @remarks
 * The main page component for the application that uses conditional rendering
 * to select which child component to display - SignIn, SurveyForm, RecCompForm,
 * or SelectedExperPanel.  The component uses the React Context API to track the
 * context of the user, survey, and selectedExper to detect which stage the end
 * user is currently in and then decide which component to render.
 *
 * @param props - React props not currently used.
 * @returns - The MainBody React component in JSX.
 */
export default function MainBody(props: any) {
  const { user } = useContext(UserContext);
  const { surveyData } = useContext(SurveyDataContext);
  const { selectedExper } = useContext(SelectedExperContext);
  const { customExper } = useContext(CustomExperContext);

  // console.log("customExper", customExper);

  if (user == null || Object.keys(user).length === 0) {
    return <SignIn />;
  } else if (surveyData == null) {
    return (
      <>
        <SurveyForm />
      </>
    );
  } else if (selectedExper == null && customExper == false) {
    return (
      <>
        <Paper>
          <RecCompForm />
        </Paper>
      </>
    );
  } else if (selectedExper == null && customExper == true) {
    return (
      <Paper>
        <UserSelectForm />
      </Paper>
    );
  } else {
    return (
      <Paper>
        <SelectedExperPanel />
      </Paper>
    );
  }
}
