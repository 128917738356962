"use clent";
import * as React from "react";
import { useContext } from "react";
import UserContext from "@/context/userContext";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/material";

/**
 * A React component to display the top navigation frame for the YEX web application.
 *
 * @remarks
 * This component uses the React Context API to keep track of the YEX user.  If the
 * YEX user had logged in, an Icon with the user ID is displayed on the right side of
 * the navigation bar.
 *
 * @param props - React component props not currently used.
 * @returns - The TopNav React component in JSX.
 */
export default function TopNav(props: any) {
  const { user } = useContext(UserContext);

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography
          variant="h3"
          align="center"
          component="div"
          sx={{ flexGrow: 1 }}
        >
          Welcome to YEX
        </Typography>
        {user && (
          <div>
            <Stack direction="row">
              <Typography sx={{ display: "flex", alignItems: "center" }}>
                <IconButton size="large" color="inherit">
                  <AccountCircle />
                </IconButton>
                {user.get("username")}
              </Typography>
            </Stack>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
}
